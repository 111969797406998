import { useEffect } from "react";
import SkipToContent from "../SkipToContent";
import NavBar from "../NavBar";
import Footer from "../Footer";
import "./FourOFour.scss";

const FourOFour = () => {
  useEffect(() => {
    document.title = "404 | Divins Mathew";
  }, []);

  return (
    <div id="four-o-four">
      <SkipToContent contentId="project-archive" />
      <NavBar />
      <div className="four-o-four-animation">
        <svg>
          <symbol id="s-text">
            <text textAnchor="middle" x="50%" y="90%">
              404
            </text>
          </symbol>
          <use className="animated-text" xlinkHref="#s-text"></use>
          <use className="animated-text" xlinkHref="#s-text"></use>
          <use className="animated-text" xlinkHref="#s-text"></use>
          <use className="animated-text" xlinkHref="#s-text"></use>
          <use className="animated-text" xlinkHref="#s-text"></use>
        </svg>
        <span className="warning-text mt-12">
          Uh-oh! You're in the wrong place.
        </span>
        <a
          data-text="Go Home"
          className="button home-button mt-12"
          href="/"
          rel="noopener noreferrer"
        >
          Go Home
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default FourOFour;
