import DesignationAndDuration from "../DesignationAndDuration";
import CompanyNameAndDuration from "../CompanyNameAndDuration";
import DotPoints from "../../../DotPoints";
import Tags from "../../../Tags";
import { getDurationStringJsx } from "../../../../utils";

const ImpressAi = () => {
  const workDone = [
    <span>
      Built authentication components with multiple third-party integrations
      that work across different clients' dedicated servers.
    </span>,
    <span>Developed a Google Chrome Extension for candidate sourcing.</span>,
    <span>
      Implemented advanced CSS animations across the product, improving the UX
      and fluidity of its pages.
    </span>,
    <span>
      Worked on establishing web accessibility across pages and integrating
      third-party tools for the same.
    </span>,
  ];
  const stack = ["React", "LESS", "SCSS", "Django", "Next.js"];

  return (
    <section id="impress-ai" className="company-wrapper">
      <CompanyNameAndDuration
        name="impress.ai"
        url="https://impress.ai/"
        duration={getDurationStringJsx(
          new Date(2022, 1, 28),
          new Date(2023, 5, 21),
          true
        )}
      />
      <DesignationAndDuration
        designation="Software Engineer"
        duration={getDurationStringJsx(
          new Date(2022, 1, 28),
          new Date(2023, 5, 21)
        )}
      />
      <DotPoints points={workDone} />
      <Tags tags={stack} />
    </section>
  );
};

export default ImpressAi;
