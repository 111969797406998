import DesignationAndDuration from "../DesignationAndDuration";
import CompanyNameAndDuration from "../CompanyNameAndDuration";
import DotPoints from "../../../DotPoints";
import Tags from "../../../Tags";
import { getDurationStringJsx } from "../../../../utils";

const OrangeHealthLabs = () => {
  const workDone = [
    <span>
      Architected, and developed orange-ui, the company's internal component
      library from scratch. Sole maintainer responsible for its development and
      documentation.
    </span>,
    <span>
      Played core role in setting company-wide standards for front-end code
      quality, code reviews, and documentation.
    </span>,
    <span>
      Help improve the core web vitals of the platform by optimizing the LCP,
      FID, and CLS, and saw a 80% improvement in the PageSpeed score.
    </span>,
  ];
  const stack = ["Next.js", "TypeScript", "SASS", "React.js", "react-query"];

  return (
    <section id="orange-health-labs" className="company-wrapper">
      <CompanyNameAndDuration
        name="Orange Health Labs"
        url="https://orangehealth.in/"
        duration={getDurationStringJsx(new Date(2023, 5, 1), undefined, true)}
      />
      <DesignationAndDuration
        designation="Software Development Engineer II"
        duration={getDurationStringJsx(new Date(2023, 5, 1))}
      />
      <DotPoints points={workDone} />
      <Tags tags={stack} />
    </section>
  );
};

export default OrangeHealthLabs;
