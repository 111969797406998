import "./DiozzIcon.scss";

const DiozzIconAnimated = () => (
  <div className="diozz-icon-wrapper">
    <div className="first-name">
      <div className="alpha d"></div>
      <div className="alpha non-cap sm i"></div>
      <div className="alpha non-cap sm v"></div>
      <div className="alpha non-cap sm i"></div>
      <div className="alpha non-cap sm n"></div>
      <div className="alpha non-cap sm s">
        <div className="left-wing"></div>
        <div className="right-wing"></div>
      </div>
    </div>
    <div className="last-name">
      <div className="alpha m">
        <div className="left-wing"></div>
        <div className="right-wing"></div>
      </div>
      <div className="alpha non-cap sm a"></div>
      <div className="alpha non-cap t">
        <div className="notch"></div>
      </div>
      <div className="alpha non-cap h"></div>
      <div className="alpha non-cap sm e"></div>
      <div className="alpha non-cap sm w">
        <div className="left-wing"></div>
        <div className="right-wing"></div>
      </div>
    </div>
  </div>
);

const DiozzIconStatic = () => (
  <div className="diozz-icon-wrapper hover-shimmer">
    <div className="d-static">
      <div className="shimmer"></div>
    </div>
    <div className="m-static">
      <div className="shimmer"></div>
      <div className="left-wing"></div>
      <div className="right-wing"></div>
    </div>
  </div>
);

export { DiozzIconAnimated, DiozzIconStatic };
