import classNames from "classnames";
import Tags from "../../Tags";

import { SiDwavesystems } from "react-icons/si";
import { MdOutlineWaves } from "react-icons/md";

const WorkItem = ({ data, leftToRight, className }) => {
  return (
    <>
      <div
        className={classNames("work-item-wrapper", className, {
          "flex-start": !leftToRight,
          "flex-end": leftToRight,
        })}
      >
        <div
          className={classNames("work-item", {
            "notch-left": !leftToRight,
            "notch-right": leftToRight,
          })}
        >
          <div className="work-item-details">
            <div className="work-item-title-wrapper">
              <div className="work-item-title mb-3">{data.title}</div>
              <div>
                {data.links?.map((link, index) => (
                  <a
                    className="mr-4 work-item-link"
                    key={index}
                    title={link.label}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.icon}
                  </a>
                ))}
              </div>
            </div>
            <div className="work-item-description">
              {leftToRight ? (
                <SiDwavesystems size={100} className="work-item-back-shape" />
              ) : (
                <MdOutlineWaves size={200} className="work-item-back-shape" />
              )}
              {data.description}
            </div>
            <Tags className="mt-2" tags={data.techStack} />
          </div>
          <div className="work-item-image">
            <img src={data.image} alt={data.title} />
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkItem;
