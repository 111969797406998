import "./DotPoints.scss";

import { VscDebugBreakpointLog } from "react-icons/vsc";
import classNames from "classnames";

const DotPoints = ({ points, className }) => (
  <section className={classNames(className)}>
    {points.map((point, index) => (
      <div key={index} className="dot-point">
        <VscDebugBreakpointLog />
        <div className="text">{point}</div>
      </div>
    ))}
  </section>
);

export default DotPoints;
