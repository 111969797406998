import React, { useRef } from "react";

import SkipToContent from "../SkipToContent";
import NavBar from "../NavBar";
import Main from "./Main";
import About from "./About";
import Experience from "./Experience";
import Work from "./Work";
import Contact from "./Contact";
import Footer from "../Footer";
import "./Home.scss";
import "../Styles/Utils.scss";

const Home = () => {
  const sectionRefs = {
    mainRef: useRef(),
    aboutRef: useRef(),
    experienceRef: useRef(),
    workRef: useRef(),
    contactRef: useRef(),
  };

  return (
    <>
      <SkipToContent contentId="about" />
      <NavBar home sectionRefs={sectionRefs} />
      <main>
        <Main ref={sectionRefs.mainRef} />
        <About ref={sectionRefs.aboutRef} />
        <Experience ref={sectionRefs.experienceRef} />
        <Work ref={sectionRefs.workRef} />
        <Contact ref={sectionRefs.contactRef} />
        <Footer showCredits />
      </main>
    </>
  );
};

export default Home;
