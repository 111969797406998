import DesignationAndDuration from "../DesignationAndDuration";
import CompanyNameAndDuration from "../CompanyNameAndDuration";
import DotPoints from "../../../DotPoints";
import Tags from "../../../Tags";
import { getDurationStringJsx } from "../../../../utils";

const KMRL = () => {
  const workDone = [
    <span>
      Architected a platform to transform and digitize a traditional paper-based
      job card management system.
    </span>,
    <span>
      Delivered the product after multiple rounds of presentations and prototype
      iterations.
    </span>,
  ];
  const stack = ["PHP", "HTML", "CSS", "JavaScript", "MySQL"];

  return (
    <section id="kmrl" className="company-wrapper">
      <CompanyNameAndDuration
        name="Kochi Metro Rail Ltd."
        url="https://kochimetro.org/"
        duration={getDurationStringJsx(
          new Date(2019, 5, 1),
          new Date(2019, 6, 1),
          true
        )}
      />
      <DesignationAndDuration
        designation="Web Development Intern"
        duration={getDurationStringJsx(
          new Date(2019, 5, 1),
          new Date(2019, 6, 1)
        )}
      />
      <DotPoints points={workDone} />
      <Tags tags={stack} />
    </section>
  );
};

export default KMRL;
