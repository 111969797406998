import React, { useRef, useState } from "react";
import classNames from "classnames";

import { sleep } from "../../utils";

import "./TabbedLayout.scss";
import useDragScroll from "../../hooks/useDragScroll";

const TabbedLayout = ({ children, selectedTabIndex: selected = 0 }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(selected);
  const [transitionAnimationClass, setTransitionAnimationClass] =
    useState("tab-content");
  const tabContainerRef = useRef();

  useDragScroll(tabContainerRef);

  const addProps = (child, index) =>
    React.cloneElement(child, {
      index,
      selected: selectedTabIndex === index,
      setSelectedTabIndex: setSelectedTabIndex,
      onTransitionStart: () =>
        setTransitionAnimationClass("tab-content tab-fade-out"),
      onTransitionEnd: () =>
        setTransitionAnimationClass("tab-content tab-fade-in"),
    });

  return (
    <section className="tab-layout-wrapper">
      <div ref={tabContainerRef} className="tabs-list">
        {children.map(addProps)}
      </div>
      <div className={transitionAnimationClass}>
        {children[selectedTabIndex].props.content}
      </div>
    </section>
  );
};

const Tab = ({
  title,
  id,
  selected,
  index,
  setSelectedTabIndex,
  onTransitionStart,
  onTransitionEnd,
}) => {
  const handleClick = async (e) => {
    if (selected) return;

    e.target.parentElement.scrollTo({
      left: e.target.offsetLeft - 30,
      behavior: "smooth",
    });
    onTransitionStart?.();
    await sleep(125);
    setSelectedTabIndex(index);
    await sleep(125);
    onTransitionEnd?.();
  };

  return (
    <div
      key={id}
      onClick={handleClick}
      className={classNames("tab-item", {
        "selected-tab-item": selected,
      })}
    >
      {title}
    </div>
  );
};

export { TabbedLayout, Tab };
