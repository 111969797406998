import { forwardRef } from "react";
import classNames from "classnames";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";

import SectionHeading from "../../SectionHeading";
import WorkItem from "./WorkItem";
import { WORKS } from "./constants";
import useFadeInOnScreen from "../../../hooks/useFadeInOnScreen";

import "./Work.scss";

const WorkWithRef = (_, ref) => {
  useFadeInOnScreen(ref, "-80px", true, "invisible", "fade-in-on-intersection");

  return (
    <section id="work" ref={ref}>
      <section className="work-contents container">
        <SectionHeading className="mb-7" heading="What I've Built" />
        <section>
          {WORKS.map((work, index) => (
            <WorkItem
              key={index}
              data={work}
              leftToRight={index % 2}
              className={classNames({ "mt-10": index !== 0 })}
            />
          ))}
        </section>
        <div className="see-all">
          <Link
            className="hyperlink"
            to="/project-archive"
            target="_blank"
            rel="noopener noreferrer"
          >
            see all
            <FiExternalLink className="ml-2" size={15} />
          </Link>
        </div>
      </section>
    </section>
  );
};

const Work = forwardRef(WorkWithRef);

export default Work;
