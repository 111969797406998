import { useEffect, useState, useRef } from "react";

const useOnScreen = ({
  ref,
  rootMargin = "0px",
  onlyOnce = false,
  callback,
}) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const didUpdate = useRef(false);

  useEffect(() => {
    let observerRefValue = null;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);

        if (
          entry.isIntersecting &&
          onlyOnce &&
          ref.current &&
          !didUpdate.current
        ) {
          observer.unobserve(ref.current);
          didUpdate.current = true;
        }
      },
      {
        rootMargin,
      }
    );

    if (ref?.current) {
      observer.observe(ref.current);
      observerRefValue = ref.current;
    }

    return () => observerRefValue && observer.unobserve(observerRefValue);
  }, [onlyOnce, rootMargin, ref]);

  useEffect(() => {
    if (callback && typeof callback === "function") {
      if (onlyOnce && didUpdate.current) return;
      callback(isIntersecting, ref);
    }
  }, [isIntersecting, onlyOnce, callback, ref]);

  return isIntersecting;
};

export default useOnScreen;
