import { useRef, useState } from "react";
import classNames from "classnames";

import "./Input.scss";

const Input = ({
  id,
  name,
  type,
  required,
  placeholder,
  renderTextArea,
  cols,
  rows,
  className,
}) => {
  const [placeHolderClass, setPlaceHolderClass] = useState("");
  const inputValue = useRef();

  const handleBlur = () => {
    inputValue.current.value = inputValue.current.value.trim();
    if (inputValue.current.value === "") setPlaceHolderClass("");
  };

  return (
    <div className={classNames("diozz-input-wrapper", className)}>
      <span
        className={classNames(
          "placeholder",
          { "input-placeholder": !renderTextArea },
          { "textarea-placeholder": renderTextArea },
          placeHolderClass
        )}
      >
        {placeholder}
      </span>

      {renderTextArea ? (
        <textarea
          ref={inputValue}
          className="diozz-text-input"
          id={id}
          name={name}
          type={type}
          required={required}
          cols={cols}
          rows={rows}
          onFocus={() => setPlaceHolderClass("suspend-placeholder")}
          onBlur={handleBlur}
        />
      ) : (
        <input
          ref={inputValue}
          className="diozz-text-input"
          id={id}
          name={name}
          type={type}
          required={required}
          onFocus={() => setPlaceHolderClass("suspend-placeholder")}
          onBlur={handleBlur}
        />
      )}
    </div>
  );
};

export default Input;
