import dayjs from "dayjs";
import preciseDiff from "dayjs-precise-range";
import { BsDot } from "react-icons/bs";

dayjs.extend(preciseDiff);

const getDurationStringJsx = (dateStart, dateEnd, skipRange) => {
  let starts = dayjs(dateStart).startOf("month");
  let ends = dayjs(dateEnd).add(1, "month").startOf("month");

  let { years, months } = dayjs.preciseDiff(ends, starts, true);

  let timeString = "";
  if (years > 0) timeString += pluralize(years, "yr", "yrs");

  if (months > 0)
    timeString +=
      (timeString === "" ? "" : " ") + pluralize(months, "mo", "mos");

  if (skipRange) return timeString;

  let duration = `${starts.format("MMM YYYY")} - ${
    dateEnd ? ends.subtract(1, "month").format("MMM YYYY") : "Present"
  }`;

  return (
    <>
      {duration} <BsDot size={25} /> {timeString}{" "}
    </>
  );
};

const pluralize = (qty, singular, plural) => {
  return qty + (qty === 1 ? singular : plural);
};

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

const toggleColorScheme = () => {
  const body = document.querySelector("body");
  body.toggleAttribute("dark-mode");
  body.toggleAttribute("light-mode");

  localStorage.setItem(
    "preferred-color-scheme",
    body.hasAttribute("dark-mode") ? "dark" : "light"
  );
};

const getColorScheme = ({ defaultPreference }) => {
  const userPreference = localStorage.getItem("preferred-color-scheme");

  if (userPreference) {
    switch (userPreference) {
      case "light":
        return "light";
      case "dark":
        return "dark";
      default:
        break;
    }
  }

  const query = ([mode]) => `(prefers-color-scheme: ${mode})`;

  const darkQuery = window.matchMedia?.(query`dark`);
  const lightQuery = window.matchMedia?.(query`light`);

  if (darkQuery?.matches) return "dark";
  if (lightQuery?.matches) return "light";

  return defaultPreference ? defaultPreference : "dark";
};

const setColorScheme = (colorScheme) => {
  const body = document.querySelector("body");

  if (colorScheme === "dark") {
    body.setAttribute("dark-mode", "");
    body.removeAttribute("light-mode");
  } else {
    body.setAttribute("light-mode", "");
    body.removeAttribute("dark-mode");
  }
  localStorage.setItem("preferred-color-scheme", colorScheme);
};

export {
  getDurationStringJsx,
  sleep,
  pluralize,
  toggleColorScheme,
  getColorScheme,
  setColorScheme,
};
