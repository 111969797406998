import { useEffect } from "react";
import useOnScreen from "./useOnScreen";

const useFadeInOnScreen = (
  ref,
  rootMargin = "0px",
  onlyOnce = false,
  classToHide = "invisible",
  classToShow = "visible"
) => {
  let isIntersecting = useOnScreen({ ref, rootMargin, onlyOnce });

  useEffect(() => ref.current.classList.add(classToHide), [ref, classToHide]);

  useEffect(() => {
    ref.current.classList.toggle(classToShow, isIntersecting);
    ref.current.classList.toggle(classToHide, !isIntersecting);
  }, [isIntersecting, ref, classToShow, classToHide]);

  return isIntersecting;
};

export default useFadeInOnScreen;
