import "./Tags.scss";

const Tags = ({ tags }) => (
  <div className="tags-wrapper">
    {tags.map((tag, index) => (
      <div key={index} className="tag">
        {tag}
      </div>
    ))}
  </div>
);

export default Tags;
