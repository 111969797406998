import { GoPlus } from "react-icons/go";

import "./BackgroundShapes.scss";

const CrossGrid = ({ top, angle = 0 }) => (
  <div className="background-shape">
    <div
      className="cross-grid"
      style={{ top: `${top}`, transform: `rotate(${angle}deg)` }}
    >
      {Array.from({ length: 56 }).map((_, index) => (
        <GoPlus key={index} className="cross" size={25} />
      ))}
    </div>
  </div>
);

const Line = ({ top, angle = 0 }) => (
  <div className="background-shape">
    <div
      className="line"
      style={{ top: `${top}`, transform: `rotate(${angle}deg)` }}
    ></div>
  </div>
);

const EllipseBorder = ({ left = "auto", right = 0, top, angle = 0 }) => (
  <div className="background-shape">
    <div
      className="circle-line"
      style={{
        top: `${top}`,
        left: `${left}`,
        right: `${right}`,
        transform: `rotate(${angle}deg)`,
      }}
    ></div>
  </div>
);

export { CrossGrid, Line, EllipseBorder };
