import { forwardRef } from "react";

import { useForm } from "@formspree/react";
import { BsPatchCheck } from "react-icons/bs";
import { MdOutlineScheduleSend } from "react-icons/md";

import SectionHeading from "../../SectionHeading";
import Input from "../../Input";
import useFadeInOnScreen from "../../../hooks/useFadeInOnScreen";

import "./Contact.scss";

const ContactWithRef = (_, ref) => {
  const [state, handleSubmit] = useForm("mgedobpe");
  const handleSubmitLocal = (event) => {
    event.preventDefault();
    handleSubmit(event);
  };
  useFadeInOnScreen(ref, "-70px", true, "invisible", "fade-in-on-intersection");

  return (
    <section id="contact" ref={ref}>
      <section className="contact-contents container">
        <SectionHeading className="mb-7" heading="Get In Touch" center />
        <div className="contact-description mt-8">
          Have a question for me? Got an idea to build the next unicorn? Feel
          free to hit me up! I'll try my best to get back.
        </div>
        <section className="contact-form-wrapper mt-10">
          {!state.succeeded && !state.submitting && (
            <form onSubmit={handleSubmitLocal}>
              <section className="contact-form mt-10">
                <Input
                  className="name-wrapper"
                  id="name"
                  placeholder="name"
                  name="name"
                  type="text"
                  required
                />
                <Input
                  className="email-wrapper"
                  placeholder="email"
                  id="email"
                  name="email"
                  type="email"
                  required
                />
                <Input
                  className="message-wrapper"
                  placeholder="message"
                  id="message"
                  name="message"
                  type="text"
                  cols="30"
                  rows="10"
                  renderTextArea
                  required
                />
              </section>
              <button
                className="button mt-8 submit-button"
                type="submit"
                data-text="Submit"
              >
                Submit
              </button>
            </form>
          )}

          {state.submitting && (
            <section className="contact-form-status contact-form-submitting">
              <MdOutlineScheduleSend size={40} />
              <div className="mt-4">Submitting...</div>
            </section>
          )}

          {state.succeeded && !state.submitting && (
            <section className="contact-form-status contact-form-submitted mt-5">
              <BsPatchCheck size={45} />
              <div className="mt-4">Thank you! I've received your message.</div>
            </section>
          )}
        </section>
      </section>
    </section>
  );
};

const Contact = forwardRef(ContactWithRef);

export default Contact;
