import classNames from "classnames";

const DesignationAndDuration = ({ designation, duration, className }) => {
  return (
    <div className={classNames(className)}>
      <div className="company-designation">{designation}</div>
      <div className="company-duration">{duration}</div>
    </div>
  );
};

export default DesignationAndDuration;
