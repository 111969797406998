import "./About.scss";

import { forwardRef, useEffect, useMemo } from "react";

import ProfileImage from "../../../assets/images/profile-image.png";
import nameAudio from "../../../assets/audio/divins-mathew.wav";

import SectionHeading from "../../SectionHeading";
import DotPoints from "../../DotPoints";
import { EllipseBorder } from "../../BackgroundShapes";

import { ABOUT_ME, RECENT_TECH } from "./constants";
import useFadeInOnScreen from "../../../hooks/useFadeInOnScreen";

const AboutWithRef = (_, ref) => {
  const audio = useMemo(() => new Audio(nameAudio), []);
  useEffect(() => audio.load(), [audio]);
  const playNameAudio = () => audio.play();

  useFadeInOnScreen(
    ref,
    "-100px",
    true,
    "invisible",
    "fade-in-on-intersection"
  );

  return (
    <section id="about" ref={ref}>
      <EllipseBorder top="45%" right="-80%" />
      <section className="about-contents container">
        <SectionHeading className="mb-7" heading="About Me" />

        <section className="about-row">
          <article className="about-text">
            <ABOUT_ME playNameAudio={playNameAudio} />
            <section className="about-recent-tech mt-5">
              <DotPoints points={RECENT_TECH[0]} className="mr-10" />
              <DotPoints points={RECENT_TECH[1]} className="ml-10" />
            </section>
          </article>

          <article className="about-image-wrapper">
            <section className="about-image-shadow"></section>
            <section className="about-image">
              <img src={ProfileImage} alt="Profile" />
            </section>
          </article>
        </section>
      </section>
    </section>
  );
};

const About = forwardRef(AboutWithRef);

export default About;
