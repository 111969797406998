import DesignationAndDuration from "../DesignationAndDuration";
import CompanyNameAndDuration from "../CompanyNameAndDuration";
import DotPoints from "../../../DotPoints";
import Tags from "../../../Tags";
import { getDurationStringJsx } from "../../../../utils";

const Cognizant = () => {
  const workDone = [
    [
      <span>
        Developed and maintained scalable components across various domains for
        the client's e-commerce website.
      </span>,
      <span>
        Modeling and designing maintainable and re-usable component
        architectures to be used across the company's products.
      </span>,
    ],
    [
      <span>
        Worked on various small applications and got familiarised with concepts
        including test-driven development, accessibility, PWAs, and analytics.
      </span>,
      <span>Spearheaded transition from ES6 to React Js.</span>,
      <span>
        Experimented with tech stacks including Angular, MongoDB, Express, and
        Node.
      </span>,
    ],
  ];
  const stack = [
    "ReactJS",
    "Redux",
    "NodeJS",
    "SASS",
    "Spring",
    "Java",
    "Thymeleaf",
    "Azure DevOps",
  ];

  return (
    <section id="cognizant" className="company-wrapper">
      <CompanyNameAndDuration
        name="Cognizant"
        url="https://www.cognizant.com/"
        duration={getDurationStringJsx(
          new Date(2020, 8, 4),
          new Date(2021, 10, 4),
          true
        )}
      />
      <DesignationAndDuration
        designation="Programmer Analyst"
        duration={getDurationStringJsx(
          new Date(2021, 8, 5),
          new Date(2021, 10, 4)
        )}
      />
      <DotPoints points={workDone[0]} />
      <DesignationAndDuration
        className="mt-6"
        designation="Programmer Analyst Trainee"
        duration={getDurationStringJsx(
          new Date(2020, 8, 4),
          new Date(2021, 7, 4)
        )}
      />
      <DotPoints points={workDone[1]} />
      <Tags tags={stack} />
    </section>
  );
};

export default Cognizant;
