const CompanyNameAndDuration = ({ name, duration, url }) => (
  <div className="company-name-wrapper mb-3">
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="hyperlink company-name mr-4"
    >
      @{name}
    </a>
    <div className="company-duration">{duration}</div>
  </div>
);

export default CompanyNameAndDuration;
