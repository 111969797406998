import "./Experience.scss";

import { forwardRef } from "react";

import { TabbedLayout, Tab } from "../../TabbedLayout";
import SectionHeading from "../../SectionHeading";

import KMRL from "./Companies/KMRL";
import RajagiriHospital from "./Companies/RajagiriHospital";
import Cognizant from "./Companies/Cognizant";
import BigBinary from "./Companies/BigBinary";
import ImpressAi from "./Companies/ImpressAi";
import OrangeHealthLabs from "./Companies/OrangeHealthLabs.jsx";

import useFadeInOnScreen from "../../../hooks/useFadeInOnScreen";

const ExperienceWithRef = (_, ref) => {
  useFadeInOnScreen(
    ref,
    "-100px",
    true,
    "invisible",
    "fade-in-on-intersection"
  );

  return (
    <section id="experience" ref={ref}>
      <section className="experience-contents container">
        <div className="line"></div>
        <SectionHeading className="mb-5" heading="Where I've worked" />
        <TabbedLayout>
          <Tab
            title="Orange Health Labs"
            key="OrangeHealthLabs"
            content={<OrangeHealthLabs />}
          />
          <Tab title="impress.ai" key="impress.ai" content={<ImpressAi />} />
          <Tab title="BigBinary" key="BigBinary" content={<BigBinary />} />
          <Tab title="Cognizant" key="Cognizant" content={<Cognizant />} />
          <Tab
            title="Rajagiri Hospital"
            key="Rajagiri Hospital"
            content={<RajagiriHospital />}
          />
          <Tab title="KMRL" key="KMRL" content={<KMRL />} />
        </TabbedLayout>
      </section>
    </section>
  );
};

const Experience = forwardRef(ExperienceWithRef);

export default Experience;
