import DesignationAndDuration from "../DesignationAndDuration";
import CompanyNameAndDuration from "../CompanyNameAndDuration";
import DotPoints from "../../../DotPoints";
import Tags from "../../../Tags";
import { getDurationStringJsx } from "../../../../utils";

const RajagiriHospital = () => {
  const workDone = [
    <span>
      Designed and developed a utility{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://play.google.com/store/apps/details?id=com.rajagiri.carecompanionnew"
        className="hyperlink"
      >
        Android app
      </a>{" "}
      for the hospital.
    </span>,
    <span>
      Built over a dozen functionalities including user authentication,
      discharge automation, directory management, canteen menu showcase,
      information board, etc.
    </span>,
    <span>
      Built a common backend for the hospital's various care-companion apps.
    </span>,
    <span>
      Developed a desktop dashboard application for care-companion app data
      management.
    </span>,
  ];
  const stack = ["Android", "Java", "PHP", "C#", "MySQL", "WinForms"];

  return (
    <section id="rajagiri-hospital" className="company-wrapper">
      <CompanyNameAndDuration
        name="Rajagiri Hospital"
        url="https://www.rajagirihospital.com/"
        duration={getDurationStringJsx(
          new Date(2020, 4, 1),
          new Date(2020, 9, 30),
          true
        )}
      />
      <DesignationAndDuration
        designation="Android Development Intern"
        duration={getDurationStringJsx(
          new Date(2020, 4, 1),
          new Date(2020, 9, 30)
        )}
      />
      <DotPoints points={workDone} />
      <Tags tags={stack} />
    </section>
  );
};

export default RajagiriHospital;
