import { Routes, Route } from "react-router-dom";

import Home from "./Home";
import ProjectArchive from "./ProjectArchive";
import FourOFour from "./FourOFour";
import { setColorScheme, getColorScheme } from "../utils";
import { useEffect } from "react";

const App = () => {
  setColorScheme(getColorScheme("dark"));

  useEffect(() => {
    //this is to prevent the theme styles from animating on load
    document.body.style.transition = "background-color 0.35s ease-in-out";
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/project-archive" element={<ProjectArchive />} />
      <Route path="/*" element={<FourOFour />} />
    </Routes>
  );
};

export default App;
