import DesignationAndDuration from "../DesignationAndDuration";
import CompanyNameAndDuration from "../CompanyNameAndDuration";
import DotPoints from "../../../DotPoints";
import Tags from "../../../Tags";
import { getDurationStringJsx } from "../../../../utils";

const BigBinary = () => {
  const workDone = [
    <span>
      Developed components for{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://neeto.com/"
        className="hyperlink"
      >
        neeto
      </a>{" "}
      products from scratch.
    </span>,
    <span>
      Modeled and designed maintainable and re-usable component architectures to
      be used across the company's products.
    </span>,
  ];
  const stack = ["ReactJS", "Ruby", "Tailwind", "PostgreSQL", "Cloudinary"];

  return (
    <section id="bigbinary" className="company-wrapper">
      <CompanyNameAndDuration
        name="BigBinary"
        url="https://www.bigbinary.com/"
        duration={getDurationStringJsx(
          new Date(2021, 10, 22),
          new Date(2022, 1, 28),
          true
        )}
      />
      <DesignationAndDuration
        designation="Software Engineer Intern"
        duration={getDurationStringJsx(
          new Date(2021, 10, 22),
          new Date(2022, 1, 28)
        )}
      />
      <DotPoints points={workDone} />
      <Tags tags={stack} />
    </section>
  );
};

export default BigBinary;
