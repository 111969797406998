import classNames from "classnames";
import "./SectionHeading.scss";

const SectionHeading = ({ prefix, heading, className, center }) => {
  return (
    <div
      className={classNames(
        "section-heading",
        { "title-center": center },
        className
      )}
    >
      {center && <span className="title-line mr-7"></span>}
      {prefix && <span className="title-number">{prefix}</span>}
      <span className="title-section">{heading}</span>
      <span className="title-line ml-7"></span>
    </div>
  );
};

export default SectionHeading;
