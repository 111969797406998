import { useEffect, useState } from "react";
import classNames from "classnames";
import { AiOutlineFork, AiOutlineStar } from "react-icons/ai";
import { socialLinks } from "./constants";

import "./Footer.scss";

const Footer = ({ showCredits }) => {
  const [starCount, setStarCount] = useState("-");
  const [forkCount, setForkCount] = useState("-");

  useEffect(() => {
    fetch("https://api.github.com/users/divinsmathew/repos")
      .then((res) => res.json())
      .then((data) => {
        const [stargazers_count, forks_count] = data.reduce(
          (acc, curr) => [
            acc[0] + curr.stargazers_count,
            acc[1] + curr.forks_count,
          ],
          [0, 0]
        );

        setStarCount(stargazers_count);
        setForkCount(forks_count);
      });
  }, []);

  return (
    <footer id="footer">
      <section className="footer-social">
        <section className="social-links mb-2">
          {socialLinks.map((item, index) => (
            <a
              key={item.name}
              title={item.name}
              href={item.link}
              rel="noopener noreferrer"
              target="_blank"
              id={item.name.toLowerCase()}
              className={classNames(
                {
                  "social-item social-item-up": index % 2 === 0,
                },
                {
                  "social-item social-item-down": index % 2 !== 0,
                }
              )}
            >
              <span key={index}>{item.icon}</span>
            </a>
          ))}
        </section>
        <div
          className={classNames("footer-sub-text", {
            "center-text": !showCredits,
          })}
        >
          @divinsmathew • 2024
        </div>
      </section>

      {showCredits && (
        <section className="footer-credits">
          <div className="footer-sub-text">Designed and developed by</div>
          <div className="footer-name mt-1">Divins Mathew</div>

          <section className="git-stats">
            <div>
              <AiOutlineStar size={15} className="git-stat-icon" />
              {starCount}
            </div>
            <div>
              <AiOutlineFork size={15} className="git-stat-icon" />
              {forkCount}
            </div>
          </section>
        </section>
      )}
    </footer>
  );
};

export default Footer;
