import { GiSpeaker } from "react-icons/gi";

export const RECENT_TECH = [
  ["React.js", "Next.js", "TypeScript"],
  ["NodeJS", "Chrome Extensions", "Tailwind CSS"],
];

export const ABOUT_ME = ({ playNameAudio }) => (
  <>
    {" "}
    <p>
      Hi there! I'm{" "}
      <span className="about-name">
        Divins Mathew (
        <GiSpeaker
          className="about-speaker"
          size={18}
          onClick={() => playNameAudio()}
        />
        ).
      </span>{" "}
      I enjoy building elegant and performant user experiences. Over the past
      three years as a software engineer, I have worked with{" "}
      <a
        className="hyperlink"
        href="https://www.cognizant.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        a multi-national corporation
      </a>
      ,{" "}
      <a
        className="hyperlink"
        href="http://impress.ai/"
        target="_blank"
        rel="noopener noreferrer"
      >
        a startup
      </a>
      ,{" "}
      <a
        className="hyperlink"
        href="https://kochimetro.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        a government agency
      </a>
      ,{" "}
      <a
        className="hyperlink"
        href="https://www.rajagirihospital.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        a private hospital
      </a>
      , and{" "}
      <a
        className="hyperlink"
        href="https://orangehealth.in/"
        target="_blank"
        rel="noopener noreferrer"
      >
        a remote diagnostic lab
      </a>
      .
    </p>
    <p className="mt-5">
      I started my days in programming playing around with the good old Turbo C
      and building{" "}
      <a
        className="hyperlink"
        href="https://drive.google.com/drive/u/0/folders/1O0mMAeHov4T3o0iDP7JON4PoaOgHBFgx"
        target="_blank"
        rel="noopener noreferrer"
      >
        arcade games
      </a>{" "}
      using Borland graphics. I developed further interest in application
      development and spent significant amounts of time developing dozens of{" "}
      <a
        className="hyperlink"
        href="https://drive.google.com/drive/u/0/folders/11rHr93baJbFWXThqK-gAoCSuVQT2ZWCW"
        target="_blank"
        rel="noopener noreferrer"
      >
        desktop apps
      </a>{" "}
      using WinForms and C# in Visual Studio, mainly utilities, to serve my own
      needs. I have authored several{" "}
      <a
        className="hyperlink"
        href="https://play.google.com/store/apps/developer?id=Divins+Mathew"
        target="_blank"
        rel="noopener noreferrer"
      >
        Android apps
      </a>
      , using Xamarin in C# and the Android Studio in Kotlin, with over 25M+
      downloads. I've also tried a hand in prototyping. I have built several IoT
      projects, including a Rubik's Cube Solving machine, a Tongue-Controlled
      Electric Wheelchair for the paralyzed, and a Smart Thermal Scanning
      Automation platform.
    </p>
    <p className="mt-5">
      These days, I build (and occasionally design) for the web and help
      companies bring their mockups to life. I specialize in the front-end and
      love working with React.
    </p>
    <p className="mt-5">
      Here are some of the technologies that I've been working with lately:
    </p>
  </>
);
