import { FiMail, FiGithub, FiLinkedin, FiTwitter } from "react-icons/fi";
import { ImCodepen } from "react-icons/im";
import { DiStackoverflow } from "react-icons/di";

export const socialLinks = [
  {
    name: "Email",
    link: "mailto:divinsmathew@gmail.com",
    icon: <FiMail size="1.37rem" />,
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/divinsmathew",
    icon: <FiLinkedin size="1.37rem" />,
  },
  {
    name: "GitHub",
    link: "https://github.com/divinsmathew",
    icon: <FiGithub size="1.37rem" />,
  },
  {
    name: "Stackoverflow",
    link: "http://stackoverflow.com/u/3201403",
    icon: <DiStackoverflow size="1.5rem" />,
  },
  {
    name: "CodePen",
    link: "https://codepen.io/divinsmathew",
    icon: <ImCodepen size="1.37rem" />,
  },
  {
    name: "Twitter",
    link: "https://twitter.com/divinsmathew",
    icon: <FiTwitter size="1.37rem" />,
  },
];
