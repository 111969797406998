import { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { DiozzIconStatic } from "../DiozzIcon";
import useMediaQuery from "../../hooks/useMediaQuery";

import { RiUser4Fill } from "react-icons/ri";
import { RiBuilding4Fill } from "react-icons/ri";
import { TiSpanner } from "react-icons/ti";
import { MdEmail } from "react-icons/md";
import { toggleColorScheme } from "../../utils";

import "./NavBar.scss";
import "./ThemeToggleButton.scss";

const NavBar = ({ home, sectionRefs }) => {
  const navOptions = [
    { label: "About", sectionId: "about", icon: <RiUser4Fill size={20} /> },
    {
      label: "Experience",
      sectionId: "experience",
      icon: <RiBuilding4Fill size={20} />,
    },
    { label: "Work", sectionId: "work", icon: <TiSpanner size={24} /> },
    { label: "Contact", sectionId: "contact", icon: <MdEmail size={20} /> },
  ];

  const previousScrollY = useRef(0);
  const [navTransitionClass, setNavTransitionClass] = useState("nav-visible");
  const [bottomNavTransitionClass, setBottomNavTransitionClass] =
    useState("bottom-nav-visible");
  const [activeSectionId, setActiveSectionId] = useState("main");

  let isPhone = useMediaQuery("(max-width: 768px)");

  const changeSectionHighlight = (entries) => {
    const visibleSection = entries.find((x) => x.isIntersecting);
    if (visibleSection?.isIntersecting)
      setActiveSectionId(visibleSection.target.id);
  };

  useEffect(
    () => {
      window.addEventListener("scroll", handleScroll, { passive: true });

      if (home) {
        const observer = new IntersectionObserver(changeSectionHighlight, {
          threshold: 0.6,
        });
        const longContainer = new IntersectionObserver(changeSectionHighlight, {
          threshold: 0.25,
        });

        observer.observe(sectionRefs.mainRef.current);
        longContainer.observe(sectionRefs.aboutRef.current);
        observer.observe(sectionRefs.experienceRef.current);
        longContainer.observe(sectionRefs.workRef.current);
        observer.observe(sectionRefs.contactRef.current);

        return () => {
          window.removeEventListener("scroll", handleScroll);

          observer.unobserve(sectionRefs.mainRef.current);
          longContainer.unobserve(sectionRefs.aboutRef.current);
          observer.unobserve(sectionRefs.experienceRef.current);
          longContainer.unobserve(sectionRefs.workRef.current);
          observer.unobserve(sectionRefs.contactRef.current);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      home,
      sectionRefs?.mainRef,
      sectionRefs?.aboutRef,
      sectionRefs?.experienceRef,
      sectionRefs?.workRef,
      sectionRefs?.contactRef,
    ].filter((ref) => ref !== undefined)
  );

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const hideNav =
      currentScrollY > previousScrollY.current &&
      currentScrollY > window.innerHeight / 2;

    setNavTransitionClass(
      classNames(
        { "nav-hidden": hideNav },
        { "nav-visible": !hideNav },
        { "nav-shadow": currentScrollY > 45 }
      )
    );

    setBottomNavTransitionClass(
      classNames(
        { "bottom-nav-hidden": hideNav },
        { "bottom-nav-visible": !hideNav }
      )
    );

    previousScrollY.current = currentScrollY;
  };

  return (
    <>
      <nav className={classNames("navbar navbar-common", navTransitionClass)}>
        <a href="/">
          <DiozzIconStatic />
        </a>

        <div className="nav-list-container">
          <ol className="nav-list">
            {navOptions.map((option, index) => (
              <li key={index}>
                <a
                  href={classNames(
                    {
                      [`#${option.sectionId}`]: home,
                    },
                    {
                      [`../#${option.sectionId}`]: !home,
                    }
                  )}
                  className={classNames({
                    selected: activeSectionId === option.sectionId,
                  })}
                >
                  {option.label}
                </a>
              </li>
            ))}
          </ol>

          <button
            onClick={toggleColorScheme}
            className="theme-toggle-button ml-3 mr-2"
          >
            <div className="sun"></div>
            <div className="core"></div>
          </button>
          <a
            href="/Divins%20Mathew%20-%20Resume.pdf"
            data-text="Résumé"
            className="button resume-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            Résumé
          </a>
        </div>
      </nav>

      {isPhone && (
        <nav
          className={classNames(
            "bottom-navbar navbar-common",
            bottomNavTransitionClass
          )}
        >
          {navOptions.map((option, index) => (
            <div key={index}>
              <a
                href={classNames(
                  {
                    [`#${option.sectionId}`]: home,
                  },
                  {
                    [`../#${option.sectionId}`]: !home,
                  }
                )}
                className={classNames({
                  selected: activeSectionId === option.sectionId,
                })}
              >
                {option.icon}
                {option.label}
              </a>
            </div>
          ))}
        </nav>
      )}
    </>
  );
};

export default NavBar;
