import { FaWindows, FaGooglePlay } from "react-icons/fa";
import { BsGithub, BsGlobe } from "react-icons/bs";

import ArCamArt from "../../../assets/images/ar-cam.png";
import CubeXArt from "../../../assets/images/cubex.png";
import MathQuickieArt from "../../../assets/images/mathquickie.png";
import CoWatchArt from "../../../assets/images/cowatch.png";

export const WORKS = [
  {
    title: "CubeX",
    description: (
      <>
        <p className="mb-3">
          Solve your Rubik's cube using the shortest possible solution from any
          valid input state. Just enter the cube state manually or simply scan
          using the camera and CubeX will guide you through the steps to solve
          your cube in minutes!
        </p>
        <p>
          Core algorithm of the CFOP solver is open source and available via
          GitHub. Available on Windows (not maintained). Also check out the
          Android app with 25M+ downloads on the Play Store.
        </p>
      </>
    ),
    image: CubeXArt,
    techStack: [
      "C#",
      "Xamarin",
      "Three.js",
      "Kociemba's 2 Phase",
      "Visual Studio",
    ],

    links: [
      {
        label: "Google Play Store",
        url: "https://play.google.com/store/apps/details?id=diozz.cubex",
        icon: <FaGooglePlay size={20} />,
      },
      {
        label: "Windows",
        url: "https://github.com/divinsmathew/cubex-desktop/releases/download/stable/CubeX.exe",
        icon: <FaWindows size={20} />,
      },
      {
        label: "GitHub",
        url: "https://github.com/divinsmathew/CubeXdotNet-Rubiks-Cube-Solver",
        icon: <BsGithub size={20} />,
      },
    ],
  },
  {
    title: "AR Cam",
    description: (
      <p>
        A marker based augmented reality camera app for the web. Powered by
        AR.js. Fast, light-weight and works on any phone with WebGL and WebRTC.
        Snap and save pictures. Ability to Translate, Rotate or Zoom models.
        Tested in Chrome and Safari.
      </p>
    ),

    links: [
      {
        label: "Web",
        url: "https://divinsmathew.github.io/ar-cam/",
        icon: <BsGlobe size={20} />,
      },
      {
        label: "GitHub",
        url: "https://github.com/divinsmathew/ar-cam",
        icon: <BsGithub size={20} />,
      },
    ],
    image: ArCamArt,
    techStack: ["JavaScript", "Three.js", "AR.js", "A-Frame"],
  },
  {
    title: "MathQuickie",
    description: (
      <>
        <p className="mb-3">
          Race with the clock and see how simple math expressions can be
          surprisingly hard to solve. MathQuickie, a fun and addicting math
          exercise for your brain! With Google Play Games integration, challenge
          your friends, track your achievements and show off your math reflex.
        </p>
      </>
    ),
    image: MathQuickieArt,
    techStack: ["C#", "Xamarin", "Android", "Visual Studio"],

    links: [
      {
        label: "Google Play Store",
        url: "https://play.google.com/store/apps/details?id=diozz.mathquickie",
        icon: <FaGooglePlay size={20} />,
      },
    ],
  },

  {
    title: "CoWatch",
    description: (
      <p>
        CoWin helps you book your COVID-19 vaccination slot (if you live in
        India). Get pinged when a new vaccination slot becomes available in your
        area through sound alerts and browser notifications.
      </p>
    ),

    links: [
      {
        label: "Web",
        url: "https://divinsmathew.github.io/cowatch/",
        icon: <BsGlobe size={20} />,
      },
      {
        label: "GitHub",
        url: "https://github.com/divinsmathew/cowatch",
        icon: <BsGithub size={20} />,
      },
    ],
    image: CoWatchArt,
    techStack: ["JavaScript", "CoWin-API"],
  },
];
