import { forwardRef, useState } from "react";
import classNames from "classnames";

import { Line, CrossGrid } from "../../BackgroundShapes";
import "./Main.scss";

const MainWithRefs = (_, ref) => {
  const [isNameGlitching, setIsNameGlitching] = useState(false);

  return (
    <section id="main" ref={ref}>
      <Line top="70%" angle={-8} />
      <CrossGrid top="18%" />

      <section className="main-contents container">
        <div className="hi">Hi, my name is</div>
        <span
          onMouseEnter={() => setIsNameGlitching(true)}
          onMouseLeave={() => setIsNameGlitching(false)}
          className={classNames("name", { glitch: isNameGlitching })}
          data-text="Divins Mathew."
        >
          Divins Mathew.
        </span>
        <div className="designation">Web / Mobile Developer.</div>
        <p className="description">
          A design-minded front-end web and Android developer. I build
          delightful user experiences and bring elegant designs to life.
          Currently working as a Software Engineer at{" "}
          <a
            href="https://orangehealth.in/"
            className="hyperlink"
            target="_blank"
            rel="noopener noreferrer"
          >
            Orange Health Labs
          </a>
          .
        </p>
        <a
          href="mailto:divinsmathew@gmail.com"
          className="button get-in-touch-button mt-10"
          data-text="Get In Touch"
        >
          Get In Touch
        </a>
      </section>
    </section>
  );
};

const Main = forwardRef(MainWithRefs);

export default Main;
