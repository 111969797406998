import { FiGithub, FiExternalLink } from "react-icons/fi";
import { BsGlobe } from "react-icons/bs";
import { GrDocumentWindows } from "react-icons/gr";
import { SiGoogleplay } from "react-icons/si";
import { HiOutlineDocumentText } from "react-icons/hi";
import { SiHackster } from "react-icons/si";
import { RiAndroidLine } from "react-icons/ri";

export const PROJECT_ARCHIVE_LIST = [
  {
    year: "2022",
    title: "Personal Website",
    builtWith: ["React JS", "SASS", "react-icons"],
    links: [
      {
        icon: <FiExternalLink />,
        url: "https://divins.dev/",
        title: "Web",
      },
    ],
  },
  {
    year: "2021",
    title: "SpaceX Dashboard",
    builtWith: ["React JS", "SASS", "react-date-range"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/spacex-dashboard/",
        title: "GitHub",
      },
      {
        icon: <FiExternalLink />,
        url: "https://spacex-dashboard-v1.netlify.app/",
        title: "Web",
      },
    ],
  },
  {
    year: "2021",
    title: "Walgreens Checkout Page and Widgets",
    builtWith: ["React JS", "SASS", "Walgreens LSG", "Thymeleaf"],
    links: [
      {
        icon: <FiExternalLink />,
        url: "https://www.walgreens.com/",
        title: "Web",
      },
    ],
  },
  {
    year: "2021",
    title: "Snake",
    builtWith: ["React JS", "SASS", "react-easy-swipe"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/snake-react-js",
        title: "GitHub",
      },
      {
        icon: <FiExternalLink />,
        url: "https://divinsmathew.github.io/snake-react-js/",
        title: "Web",
      },
    ],
  },
  {
    year: "2021",
    title: "CoWatch - COVID Vaccine Tracker",
    builtWith: ["JavaScript", "CoWin-API"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/cowatch",
        title: "GitHub",
      },
      {
        icon: <FiExternalLink />,
        url: "https://divinsmathew.github.io/cowatch/",
        title: "Web",
      },
    ],
  },
  {
    year: "2021",
    title: "AR Cam",
    builtWith: ["JavaScript", "Three.js", "AR.js", "A-Frame", "ZingTouch"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/ar-cam",
        title: "GitHub",
      },
      {
        icon: <FiExternalLink />,
        url: "https://divinsmathew.github.io/ar-cam/",
        title: "Web",
      },
    ],
  },
  {
    year: "2020",
    title: "Sports Day Score Board",
    builtWith: ["C#", "WinForms", "Microsoft Speech Synthesizer"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/sports-day-scoreboard",
        title: "GitHub",
      },
      {
        icon: <GrDocumentWindows />,
        url: "https://github.com/divinsmathew/sports-day-scoreboard/releases/download/stable/Sports.Day.Scoreboard.exe",
        title: "Windows Executable",
      },
    ],
  },
  {
    year: "2020",
    title: "Eliza Bot",
    builtWith: ["C#", "WinForms"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/eliza-bot",
        title: "GitHub",
      },
      {
        icon: <GrDocumentWindows />,
        url: "https://github.com/divinsmathew/eliza-bot/releases/download/stable/Eliza.exe",
        title: "Windows Executable",
      },
    ],
  },
  {
    year: "2020",
    title: "Billie",
    builtWith: ["C#", "WinForms", "OpenXML"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/billie",
        title: "GitHub",
      },
      {
        icon: <GrDocumentWindows />,
        url: "https://github.com/divinsmathew/billie/releases/download/stable/Billie.Release.v1.0.0.0.zip",
        title: "Windows Executable Archive",
      },
    ],
  },
  {
    year: "2020",
    title: "Care Companion",
    builtWith: ["Android", "Java", "Picasso", "MySQL", "WinForms", "MySQL"],
    links: [
      {
        icon: <SiGoogleplay />,
        url: "https://play.google.com/store/apps/details?id=com.rajagiri.carecompanionnew",
        title: "Google Play Store",
      },
    ],
  },
  {
    year: "2020",
    title: "AutoTemp",
    builtWith: [
      "Arduino",
      "Android",
      "C",
      "C#",
      "Xamarin",
      "MySQL",
      "WinForms",
    ],
    links: [
      {
        icon: <HiOutlineDocumentText size={23} />,
        url: "https://docs.google.com/document/d/1HvFrb7tnDb6Lru-97pmlsfSfGOzrQdFfxpoYP2tX2gg/",
        title: "Doc",
      },
      {
        icon: <BsGlobe />,
        url: "https://timesofindia.indiatimes.com/city/kochi/student-develops-thermal-scanner-with-in-built-cam/articleshow/76432144.cms",
        title: "News",
      },
    ],
  },
  {
    year: "2019",
    title: "DumpIt",
    builtWith: ["C#", "WinForms", "Google Drive API"],
    links: [
      {
        icon: <GrDocumentWindows />,
        url: "https://drive.google.com/uc?export=download&id=0B8vBGx96tStVdmhwb1JfNUlweTQ",
        title: "Windows Executable",
      },
    ],
  },
  {
    year: "2019",
    title: "Bolt IoT Client Library",
    builtWith: ["C#", "WinForms", "Bolt IoT API"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/Bolt-IoT-API-.NET",
        title: "GitHub",
      },
    ],
  },
  {
    year: "2019",
    title: "Video Frame Extractor",
    builtWith: ["C#", "WinForms", "MediaToolkit"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/video-frame-extractor",
        title: "GitHub",
      },
      {
        icon: <GrDocumentWindows />,
        url: "https://github.com/divinsmathew/video-frame-extractor/releases/download/v1.0.0/Frame.Extractor.zip",
        title: "Windows Executable Archive",
      },
    ],
  },
  {
    year: "2019",
    title: "Echo Music Player",
    builtWith: ["Android", "Kotlin", "WaveInApp"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/echo",
        title: "GitHub",
      },
      {
        icon: <RiAndroidLine size={22} />,
        url: "https://github.com/divinsmathew/echo/releases/download/stable/app-release.apk",
        title: "App APK",
      },
    ],
  },
  {
    year: "2019",
    title: "Smart Door with Face Unlock",
    builtWith: [
      "C#",
      "WinForms",
      "Face++ API",
      "AForge.NET",
      "Arduino",
      "Bolt Iot API",
    ],
    links: [
      {
        icon: <SiHackster />,
        url: "https://www.hackster.io/divinsmathew/smart-door-with-face-unlock-273e06",
        title: "Hackster",
      },
    ],
  },
  {
    year: "2018",
    title: "TeaKM",
    builtWith: ["C#", "Android", "Xamarin"],
    links: [
      {
        icon: <SiGoogleplay />,
        url: "https://play.google.com/store/apps/details?id=diozz.teakm",
        title: "Google Play Store",
      },
    ],
  },
  {
    year: "2018",
    title: "MathQuickie",
    builtWith: ["C#", "Android", "Xamarin"],
    links: [
      {
        icon: <SiGoogleplay />,
        url: "https://play.google.com/store/apps/details?id=diozz.teakm",
        title: "Google Play Store",
      },
    ],
  },
  {
    year: "2018",
    title: "BookMyShow Watcher",
    builtWith: ["C#", "WinForms"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/bookmyshow-watcher",
        title: "GitHub",
      },
      {
        icon: <GrDocumentWindows />,
        url: "https://github.com/divinsmathew/bookmyshow-watcher/releases/download/stable/BMSWatcher.exe",
        title: "Windows Executable Archive",
      },
    ],
  },
  {
    year: "2018",
    title: "Google Play Gift Card Generator",
    builtWith: ["C#", "WinForms"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/google-play-gift-card-generator",
        title: "GitHub",
      },
      {
        icon: <GrDocumentWindows />,
        url: "https://github.com/divinsmathew/google-play-gift-card-generator/releases/download/stable/Google.Play.Gift.Card.Generator.exe",
        title: "Windows Executable Archive",
      },
    ],
  },
  {
    year: "2016",
    title: "CubeX - Cube Solver, Virtual Cube and Timer",
    builtWith: ["C#", "JavaScript", "Xamarin", "Android", "Cuber", "ColorMine"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/CubeXdotNet-Rubiks-Cube-Solver",
        title: "GitHub",
      },
      {
        icon: <SiGoogleplay />,
        url: "https://play.google.com/store/apps/details?id=diozz.cubex",
        title: "Google Play Store",
      },
    ],
  },
  {
    year: "2016",
    title: "CubeX Fridrich Solver - .NET Library",
    builtWith: ["C#"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/CubeXdotNet-Rubiks-Cube-Solver",
        title: "GitHub",
      },
    ],
  },
  {
    year: "2015",
    title: "CubeX Desktop",
    builtWith: ["C++", "WinForms"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/cubex-desktop",
        title: "GitHub",
      },
      {
        icon: <GrDocumentWindows />,
        url: "https://github.com/divinsmathew/cubex-desktop/releases/download/stable/CubeX.exe",
        title: "Windows Executable",
      },
    ],
  },
  {
    year: "2014",
    title: "2048",
    builtWith: ["Borland C", "Borland Graphics"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/2048",
        title: "GitHub",
      },
      {
        icon: <GrDocumentWindows />,
        url: "https://github.com/divinsmathew/2048/releases/download/stable/2048_setup.exe",
        title: "Windows Executable",
      },
    ],
  },
  {
    year: "2014",
    title: "Piano",
    builtWith: ["Borland C"],
    links: [
      {
        icon: <FiGithub />,
        url: "https://github.com/divinsmathew/piano",
        title: "GitHub",
      },
      {
        icon: <GrDocumentWindows />,
        url: "https://github.com/divinsmathew/piano/releases/download/stable/Piano_Setup.exe",
        title: "Windows Executable",
      },
    ],
  },
];
