import "./SkipToContent.scss";

const SkipToContent = ({ contentId }) => {
  return (
    <a tabIndex="0" id="skip-to-content" href={`#${contentId}`}>
      Skip to content
    </a>
  );
};

export default SkipToContent;
