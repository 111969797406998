import { useEffect } from "react";

import SectionHeading from "../SectionHeading";
import { PROJECT_ARCHIVE_LIST } from "./constants";
import { BsDot } from "react-icons/bs";
import ScrollReveal from "scrollreveal";

import SkipToContent from "../SkipToContent";
import NavBar from "../NavBar";
import Footer from "../Footer";

import "./ProjectArchive.scss";

const ProjectArchive = () => {
  useEffect(() => {
    document.title = "Project Archive | Divins Mathew";
    const slideUp = {
      distance: "2rem",
      origin: "bottom",
      interval: 70,
    };
    ScrollReveal().reveal(".project-archive-heading", slideUp);
    ScrollReveal().reveal("thead tr", {
      ...slideUp,
      delay: 100,
    });
    ScrollReveal().reveal(".to-reveal", {
      ...slideUp,
      delay: 200,
    });
  }, []);

  return (
    <>
      <SkipToContent contentId="project-archive" />
      <NavBar />
      <main id="project-archive" className="container pb-17">
        <SectionHeading
          className="project-archive-heading mt-15 mb-13"
          heading="Project Archive"
        />
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th className="year-hide">Year</th>
              <th>Title</th>
              <th className="tech-head">Tech</th>
              <th>Links</th>
            </tr>
          </thead>

          <tbody>
            {PROJECT_ARCHIVE_LIST.map((project, index) => (
              <tr key={index} class="to-reveal">
                <td className="serial-number">{index + 1}.</td>
                <td className="year-hide">{project.year}</td>
                <td>{project.title}</td>
                <td className="tech-row">
                  {project.builtWith
                    .map((tech, techIndex) => (
                      <span key={`tech-${index}-${techIndex}`}>{tech}</span>
                    ))
                    .reduce((acc, tech) => [
                      acc,
                      <BsDot key={`tech-${index}`} size={24} />,
                      tech,
                    ])}
                </td>
                <td className="project-links">
                  {project.links.map((link, index) => (
                    <a
                      key={`${project.title}-link-${index}`}
                      className="mr-3 work-item-link"
                      title={link.title}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.icon}
                    </a>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>

      <Footer />
    </>
  );
};

export default ProjectArchive;
